import {animateInViewport} from "../transition";
import {windowSize} from "../layout";

$( '.image-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 0,
		breakpoints: {
			1024: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );

	let tabElements = $this.parents( '.carousel-container' ).find( '.swiper-tab' );

	tabElements.each( function( index ) {
		$( this ).on( 'click', function() {
			swiper.slideTo( index );
			tabElements.removeClass( 'active' );
			$( this ).addClass( 'active' );
		} );
	} );
} );

$( '.content-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		autoHeight: true,
		loop: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 0,
		breakpoints: {
			1024: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );

	let tabElements = $this.parents( '.carousel-container' ).find( '.swiper-tab' );

	tabElements.each( function( index ) {
		$( this ).on( 'click', function() {
			swiper.slideTo( index );
			tabElements.removeClass( 'active' );
			$( this ).addClass( 'active' );
		} );
	} );
} );

$( '.posts-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1.1,
		spaceBetween: 24,
		breakpoints: {
			1024: {
				slidesPerView: 3.2,
			},
		},
	} );

	let slidersCount = swiper.slides.length;
	if ( windowSize.width >= 1024 ) {
		slidersCount = swiper.slides.length - 2;
	}

	let $progressBar = $this.parents( '.carousel-container' ).find( '.progress-bar' );

	$progressBar.css( { 'width': 100 / slidersCount + '%' } );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		$progressBar.css( { 'width': (100 / slidersCount) * (this.activeIndex + 1) + '%' } );
	} );
} );

$( '.events-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1.1,
		spaceBetween: 24,
		breakpoints: {
			1024: {
				slidesPerView: 4,
				spaceBetween: 64,
			},
		},
	} );

	let slidersCount = swiper.slides.length;
	if ( windowSize.width >= 1024 ) {
		slidersCount = swiper.slides.length - 5;
	}

	let $progressBar = $this.parents( '.carousel-container' ).find( '.progress-bar' );

	$progressBar.css( { 'width': 100 / slidersCount + '%' } );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		$progressBar.css( { 'width': (100 / slidersCount) * (this.activeIndex + 1) + '%' } );
	} );
} );

$( '.text-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1.25,
		spaceBetween: 40,
		breakpoints: {
			1024: {
				slidesPerView: 2.3,
				spaceBetween: 120,
			},
		},
	} );

	let slidersCount = swiper.slides.length;
	if ( windowSize.width >= 1024 ) {
		slidersCount = swiper.slides.length - 1;
	}

	let $progressBar = $this.parents( '.carousel-container' ).find( '.progress-bar' );

	$progressBar.css( { 'width': 100 / slidersCount + '%' } );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		$progressBar.css( { 'width': (100 / slidersCount) * (this.activeIndex + 1) + '%' } );
	} );
} );

$( '.properties-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 2.3,
				spaceBetween: 20,
			},
			1680: {
				slidesPerView: 3.3,
				spaceBetween: 20,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.simple-posts-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: true,
		autoHeight: false,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.hero-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		loop: true,
		grabCursor: false,
		slidesPerView: 1,
		spaceBetween: 0,
		autoplay: {
			delay: 3000,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ['calc(100% + 5rem)', 0, 0],

			},
			next: {
				translate: [0, 0, -400],
			},
		},
		speed: 1000,
	} );
} );
