$(function () {
    $('.php-form').each(function () {
        const $form = $(this);
        $form.find('input, textarea, button').each(function () {
            const $this = $(this);

            function removeValidationMessage() {
                $this.parent().find('.form-validation-message').remove();
            }

            $this.change(removeValidationMessage);
            $this.keyup(removeValidationMessage);
        });
        $form.submit(function (event) {
            event.preventDefault();

            const $form = $(this);
            const $submit = $form.find('button[type="submit"]');
            $form.find('.form-validation-message').remove();

            $submit.attr('disabled', 'disabled');
            $submit.parent().addClass('flex flex-col');
            $form.append('<span class="form-validation-message form-validation-info-message">' + settings.translation.sendingMail + '</span>');
            let data = new FormData(this);
            fetch(settings.siteAddress + '/helpers/contact/contact.php', {
                method: 'post',
                body: new URLSearchParams(data).toString(),
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                },
                cache: 'no-store',
                credentials: 'include',
            }).then(function (response) {
                $form.find('.form-validation-message').remove();
                return response.text();
            }).then(function (response) {
                response = JSON.parse(response);

                if (response.result === 'error') {
                    console.error('Server response', response);
                    if (response.message) {
                        $form.append('<span class="form-validation-message form-validation-error-message">' + response.message + '</span>');
                    }
                    if (response.fields) {
                        for (const [field, message] of Object.entries(response.fields)) {
                            $form.find('#' + field + ', label[for=' + field.replace(/\./g, '_') + ']').each(function () {
                                $form.append('<span class="form-validation-message form-validation-error-message">' + message + '</span>');
                                return false;
                            });
                        }
                    }

                    return;
                }

                location.href = $form.attr('data-success-redirect');
            }).catch(function (error) {
                console.error(error);
                $form.append('<span class="form-validation-message form-validation-error-message">' + settings.translation.genericError + '</span>');
            }).finally(function () {
                $submit.removeAttr('disabled');
            });
            return false;
        });
    });
});
