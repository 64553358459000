import {animateInViewport} from "./transition";

let jQueryBridget = require( 'jquery-bridget' );
let Masonry = require( 'masonry-layout' );

jQueryBridget( 'masonry', Masonry, $ );

export const windowSize = {
	width: $( window ).width(),
};

$( function() {
	$( 'html,body' ).scrollTop( 0 );

	window.msnry = $( '.masonry' ).on( 'layoutComplete', function() {
		animateInViewport();
	} ).masonry( {
		itemSelector: '.masonry-item',
		gutter: 24,
		columnWidth: '.masonry-item:not(.hidden):not(.filtered)',
		transitionDuration: 0,
		horizontalOrder: true,
	} );

	$( 'a' ).each( function() {
		const $this = $( this );
		const html = $this.html();

		if ( html.includes( '@' ) ) {
			$this.html( html.replace( '@', '@&#x200b;' ) );
		}
	} );
} );

$( window ).on( 'resize', function() {
	windowSize.width = $( this ).width();
} );

$( document ).ready( function() {
	function applyParallax( $parallax ) {
		var parallaxOffset = $parallax.offset().top;

		function updateParallax() {
			let adjustedScrollTop = $( this ).scrollTop() - parallaxOffset;
			let bgPosition = 'center ' + adjustedScrollTop + 'px';

			$parallax.css( {
				'background-position': bgPosition,
			} );
		}

		// Initial application of parallax effect
		updateParallax();

		// Attach the updateParallax function to the scroll event
		$( window ).scroll( updateParallax );

		// Attach the updateParallax function to the resize event
		$( window ).resize( updateParallax );
	}

	function checkScreenSize() {
		let screenWidth = $( window ).width();

		if ( screenWidth > 1023 ) {
			$( '.parallax' ).each( function() {
				var $parallax = $( this );
				applyParallax( $parallax );
			} );
		}
	}

	checkScreenSize();
	$( window ).resize( checkScreenSize );
} );
